/* default */
body {
  margin: 0;
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
  line-height: 1.8;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Dosis';
  src: url('./fonts/Dosis-VariableFont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

section {
  width: 80vw;
  margin: 0 auto;
  padding-top: 80px;
}

h1, h2, h3 {
  font-family: 'dosis', sans-serif;
}

h1 {
  margin-bottom: 40px;
}

button {
  border: 1px solid;
  padding: 15px 27px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2em;
  font-family: 'dosis', sans-serif;
  margin: 15px;
}

button:hover {
  background: #222250;
  color: #ffffff;
}

video {
  max-height: 90vh;
}

.video {
  position: relative;
  height: 0;
  padding-top: 56.25%;
  margin-bottom: 20px;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sp {
  display: none;
}

@media screen and (max-width: 640px) {
  .pc {
    display: none;
  }

  .sp {
    display: block;
  }

  section h1 {
    text-align: center;
  }
}


/* header */
header {
 padding: 10px 10vw;
 width: 100%;
 box-sizing: border-box;
 background: #ffffff;
 position: fixed;
 z-index: 10;
 top: 0;
}

.blue-header {
  transition: background 3s;
  background: #5ecbf5;
}

.header-left {
  float: left;
}

header button {
  padding: 5px 10px;
  font-size: 1em;
}

.logo a {
  text-decoration: none;
  color: #5ecbf5;
}

.blue-header .logo a{
  color: #ffffff;
}

.blue-header .logo a:hover{
  color: #222250;
  transition: 0.3s;
}


nav {
  float: right;
}

nav ul {
  list-style: none;
  margin: 0;
  margin-top: 5px;
  
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  
  -webkit-fllinktoarchiveex-flow: row wrap;
  justify-content: space-around;
}

nav li {
  width: 100px;
  text-align: center;
  font-size: 0.8em;
}

.white-header .pc .active a, .white-header .pc li a:hover {
  color: #5ecbf5;
  transition: 0.3s;
}

.blue-header .pc .active a, .blue-header .pc li a:hover {
  color: #222250;
  transition: 0.3s;
}

li a {
  text-decoration: none;
  color: #000;
}

.blue-header li a {
  color: #ffffff;
}

button {
  background: transparent;
}

@media screen and (max-width: 640px) {
  header {
    height: 4rem;
  }

  .header-left {
    margin-top: 10px;
  }

  .blue-header .sp button div {
    background: #ffffff;
  }

  nav {
    height: 100vh;
    width: 100vw;
    background-color: #5ecbf5;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    font-size: 1.5em;
  }

  nav ul {
    display: block;
    margin: 0;
    padding: 0;
    z-index: 10;
  }

  nav ul li {
    font-size: 2em;
    margin: 0 auto;
    width: auto;
  }

}


/* home main */
.homemain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
  width: 60vw;
}

.logo {
  color: #5ecbf5;
}

.Typist {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.typing {
  font-size: 1.8em;
  letter-spacing: .10em;
}

.Cursor--blinking {
  display: none;
}

.highlight {
  border-bottom: 2px solid #5ecbf5;
}

.hand {
  margin: 0;
  display: inline;
}

@keyframes wiggle {
  0% { transform: rotate(-10deg); }
  25% { transform: rotate(10deg); }
  50% { transform: rotate(-10deg); }
  75% { transform: rotate(10deg); }
  100% { transform: rotate(-10deg); }
}

.flag {
  display: inline-block;
  margin: 0;
  -webkit-animation: flip 1s infinite;
  -moz-animation: flip 1s infinite;
  animation: flip 1s infinite;
}

@keyframes flip {
  0% { transform: rotate3d(0, 1, 0, 0deg); }
  50% { transform: rotate3d(0, 1, 0, 180deg); }
  100% { transform: rotate3d(0, 1, 0, 0deg); }
}

.grow {
  display: inline-block;
  margin: 0;
  -webkit-animation: updown 1s infinite;
  -moz-animation: updown 1s infinite;
  animation: updown 1s infinite;
}

@keyframes updown {
  0%, 100% { transform: translateY(0); }
  40% { transform: translateY(-3px); }
  60% { transform: translateY(-6px); }
}

.arrow {
  position: absolute;
  bottom: 40px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 50px;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
}

@media screen and (max-width: 640px) {
  .arrow {
    width: 30px;
  }

  .Typist {
    font-size: 0.7em;
  }
}

/* skills */
#skills img {
  height: 30px;
}

.skills-wrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  grid-row-gap: 75px;
}

.skill-name {
  margin-top: 15px;
}

@media screen and (max-width: 640px) {
  .skills-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* projects */
.projects-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
}

.project-thumbnail {
  color: #000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.thumbnail {
  overflow: hidden;
}

.project-thumbnail img {
  transition: .3s ease-in-out;
}

/* .project-thumbnail img:hover {
  transform: scale(1.1);
  transition: 0.3s;
} */

.skills {
  padding: 7px 15px;
  font-size: 0.9em;
}

.projects-wrapper h3 {
  padding: 0;
  margin-bottom: 5px;
  margin-top: 18px;
}

.project-title {
  display: flex;
}

.project-title div:first-child {
   margin-right: auto;
}

.project-title a {
  display: inline-block;
}

.linkicon {
  height: 1.2em;
  margin-left: 10px;
  vertical-align: middle;
  width: 20px;
}

.linkicon:hover > .cls-1 {
  fill: #5ecbf5;
  transform: translateY(-2px);
  transition: 0.3s;
}

.projects-wrapper p {
  margin: 0;
}

@media screen and (max-width: 640px) {
  .projects-wrapper {
    display: block;
  }

  .project {
    margin-bottom: 30px;
  }
}

/* about */
.profile-image {
  float: left;
  width: 25%;
}

.profile-text {
  float: right;
  width: 70%;
}

.profile-text p {
  padding: 0;
  margin: 0;
}

.profile-wrapper {
  overflow: hidden;
}

.email {
  font-size: 1.3em;
  font-weight: 700;
}

.resume-button {
  text-align: left;
}

.resume-button button {
  margin-left: 0;
}

@media screen and (max-width: 640px) {
  .profile-image, .profile-text {
    width: 100%;
  }

  .profile-image {
    text-align: center;
  }

  .profile-image img {
    width: 80%;
  }

  .profile-text p {
    padding-top: 20px;
  }

  .resume-button {
    text-align: center;
  }

  .resume-button button {
    margin: 15px;
  }

}

/* footer */
footer section {
  padding-top: 10px;
  text-align: center;
}

footer h1 {
  margin-bottom: 10px;
}

footer {
  background-color: #5ecbf5;
  color: #ffffff;
}

.snsicon {
  margin: 10px 5%;
  width: 30px;
}

.snsicon > .cls-1 {
  fill: #ffffff;
}

.snsicon:hover > .cls-1 {
  fill: #222250;
  transition: 0.3s;
}

.copyright {
  text-align: center;
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .snslink {
    text-align: center
  }

  .snslink a {
    margin: 0 5%;
  }

  .snsicon {
    margin: 0 auto;
  }
}

/* detail main */
.detailmain {
  height: 85vh;
}

.detailmain h1 {
  margin: 0;
}

.detailmain-flexbox {
  position: absolute;
  top: 7vh;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  align-items: center;
}

.detailmain-box {
  width: 50%;
  margin-top: -50px;
  overflow: hidden;
}

.detailmain-box img {
  margin-top: 50px;
  width: 70vw;
}

@media screen and (max-width: 640px) {
  .detailmain-flexbox {
    display: block;
  }

  .detailmain-box {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .detailmain-box-text {
    margin-bottom: -50px;
  }

  .detailmain-box-image {
    margin-top: -50px;
  }
  
  .detailmain-box img {
    width: 80%;
  }


}

/* overview */
#overview img {
  margin-top: 30px;
}

/* detail-logo */

/* branding package */
.branding-package img{
  width: 50%;
}

/* moodboard */
.moodboard img {
  width: 50%;
  vertical-align: top;
}

/* paper prototyping */

/* mockup */
.mockup-button {
  text-align: center;
}

.mockup img {
  margin: 20px 0;
}

/* product */
.product-buttons {
  text-align: center;
}

/* project nav */
.project-nav {
  overflow: hidden;
}

.project-nav .fas {
  margin: 10px 20px;
}

.project-nav a {
  text-decoration: none;
  color: #000;
}

.project-nav a i {
  vertical-align: middle;
}

.left-arrow {
  float: left;
  width: 50%;
  display: inline-block;
  text-align: right;
}

.right-arrow {
  float: right;
  width: 50%;
}

.project-nav a:hover {
  color: #5ecbf5;
  transition: 0.3s;
}

.project-nav a.disabled {
  pointer-events: none;
  color: #9e9e9ed1;
}

@media screen and (max-width: 640px) {
  .project-nav {
    font-size: 12px;
  }
}
